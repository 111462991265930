<template>
    <div>
        <section class="w-50 pe-4">
            <h1 class="mb-2">Dealer</h1>
            <FormItem type="text" v-model="item.name" :label="$t('overview.name')" sizeItem="6" sizeLabel="6" :required="true" />
            <FormItem type="email" v-model="item.email" :label="$t('form.email')" sizeItem="6" sizeLabel="6" :required="true" />
            <FormItem type="text" v-model="item.debtor_number" :label="$t('location.debtornr')" sizeItem="6" sizeLabel="6" :required="true" />
        </section>
        <div :class="{'d-none': !item.uuid, 'd-flex': item.uuid}">
            <label class="col-sm-3">UUID</label>
            <p class="col">{{item.uuid}}</p>
        </div>
    </div>
    <FooterButtons @prev="prev" @save="save" :disabled="isSaving" />
</template>

<script>
    import dealerService from '@/services/DealerService';
    import FooterButtons from '@/components/FooterButtons.vue';
    export default {
        name: 'DealerDetail',
        components: {
            FooterButtons
        },
        data() {
            return {
                item: {
                    "email": "",
                    "name": "",
                    "debtor_number": ""
                },
                franchises: [],
                hospitality_groups: [],
                isNew: true,
                isSaving: false
            }
        },
        methods: {
            validate(){
                //Validation
                const els = document.querySelectorAll("section input[type='text'], section input[type='email']");
                let valid = true;
                els.forEach(el => {
                    if(!el.value){
                        el.classList.add("is-invalid");
                        if(!el.classList.contains("has-onfocus")){
                            el.addEventListener("focus", (thisEl) => {
                                thisEl.target.classList.remove("is-invalid");
                            })
                            el.classList.add("has-onfocus");
                        }
                        valid = false;
                    }
                })
                return valid;
            },
            prev(){
                this.$router.push({'name': "Dealers"});
            },
            save() {
                if(!this.validate()){
                    return;
                }
                this.isSaving = true;
                dealerService.updateOrStore(this.item).then(response => {
                    this.$router.push({'name': "Dealers"});
                }).catch(e => {
                    this.isSaving = false;
                    this.toastError(e);
                });
            }
        },
        mounted(){
            const locationId = this.$route.params.id;
            this.isNew = locationId == 'nieuw';
            if(!this.isNew){
                dealerService.show(locationId).then(response => {
                    this.item = response.data;
                });
            }
        }
    }

</script>